//@ts-nocheck
import { useListen } from '@wemade-vsf/composables';
import { useMultiLoginStore } from '@wemade-vsf/multilogin';
const moduleOptions = JSON.parse(`{"cookiesDefaultOpts":{"httpOnly":false,"secure":false,"sameSite":"Strict","path":"/"}}`);

const listenerPlugin = async ({ app }) => {
  const { cookiesDefaultOpts } = moduleOptions;
  if (app.$cookies.get('vsf-sales-token', cookiesDefaultOpts) && !app.$cookies.get('vsf-customer', cookiesDefaultOpts)) {
    app.$cookies.set('vsf-customer', app.$cookies.get('vsf-sales-token'), cookiesDefaultOpts);
    app.$cookies.remove('vsf-cart', cookiesDefaultOpts);
    app.$cookies.remove('vsf-quote-cart', cookiesDefaultOpts);
  }
  useListen('user:afterLoad', (user) => {
    const multiLoginStore = useMultiLoginStore();
    if (user?.is_sales_person) {
      multiLoginStore.$patch((state) => {
        state.isSalesPerson = true;
        state.salesRepId = user.sales_rep_id;
        state.salesRelations = user.sales_relations;
      });
      app.$vsf.$magento.config.state.setSalesToken(app.$vsf.$magento.config.state.getCustomerToken());
    }
  });

  useListen('multilogin:afterSwitch', () => {
    app.$vsf.$magento.config.state.setCartId(null);
    app.$vsf.$magento.config.state.setCacheId(null);

    if (process.browser && window) window.location.reload();
  })
}

export default listenerPlugin;