
































































import {
  defineComponent,
  useContext,
  onMounted,
  computed,
  watch,
} from '@nuxtjs/composition-api'
import { SfOverlay } from '@storefront-ui/vue'
import HeaderLogo from 'components/theme/Header/Logo.vue';
import { useUiState, useConfig, useListen } from '@wemade-vsf/composables';
import { useSearch } from '@wemade-vsf/search';
import { useUser } from '@wemade-vsf/customer';
import { useMultilogin } from '@wemade-vsf/multilogin';
import CartNavigation from 'components/theme/Header/CartNavigation.vue';
import MobileNavigationBar from 'components/theme/Header/MobileNavigationBar.vue';
import TopNavigation from 'components/theme/Header/TopNavigation.vue';
import WebsiteSwitcher from 'components/WebsiteSwitcher.vue';

export default defineComponent({
  name: 'AppHeader',
  components: {
    CartNavigation,
    HeaderLogo,
    MegaMenu: () => import('components/megamenu/MegaMenu.vue'),
    MobileNavigationBar,
    SfOverlay,
    TopNavigation,
    WebsiteSwitcher,
    SearchBar: () => import('components/search/SearchBar.vue'),
    SearchResults: () => import(/* webpackPrefetch: true */ 'components/search/SearchResults.vue'),
    MegaMenuSidebar: () => import('components/megamenu/MegaMenuSidebar.vue'),
    TopBar: () => import('components/theme/Header/TopBar.vue'),
    CompanyList: () => import('components/multilogin/CompanyList.vue')
  },
  props: {
    menuOrientation: {
      type: String,
      default: 'horizontal'
    }
  },
  setup () {
    const { $config } = useContext();
    const { 
      isMobileMenuOpen: isMobileMenu,
      toggleMobileMenu,
      hideSearch
    } = useUiState();


    const {
      isSearchOpen
    } = useSearch();

    const { isSalesPerson, salesRelations, loadSalesRelations } = useMultilogin(); 
    
    const { isAuthenticated, load: loadUser, user } = useUser();
    const { config } = useConfig();
    const showWishlist = $config.wishlist?.enable ?? false;
    const showCart = computed(() => {
      if (!user.value) {
        return !(config.value?.storefront?.hide_cart_button ?? false)
      }
      return !user.value.hide_cart_button
    })
    

    const topBarConfig = computed(() => {
      if (config.value?.storefront?.top_bar_block_enabled && config.value?.storefront?.top_bar_block_identifier) {
        return {
          background: config.value.storefront.top_bar_block_background_color,
          identifier: config.value.storefront.top_bar_block_identifier
        }
      }
      return null
    })

    watch(() => isSalesPerson.value, (value) => {
      if (value) {
        document.documentElement.classList.add('is-sales')
      } else {
        document.documentElement.classList.remove('is-sales')
      }
    })

    onMounted(async() => {
      if (isAuthenticated.value && !user.value) {
        await loadUser();
      }
      if (user.value && !salesRelations.value) {
        await loadSalesRelations();
        if (isSalesPerson.value) {
          document.documentElement.classList.add('is-sales')
        }
      }
    });

    const sticky = config.value?.storefront?.sticky_header ?? false;

    return {
      isMobileMenu,
      isSearchOpen,
      showWishlist,
      showCart,
      sticky,
      toggleMobileMenu,
      hideSearch,
      topBarConfig,
      isSalesPerson,
      salesRelations
    }
  }
})
