import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _19e59a16 = () => interopDefault(import('../_templates/pages/checkout/Checkout.vue' /* webpackChunkName: "" */))
const _9c2d941a = () => interopDefault(import('../_templates/pages/checkout/Checkout/Account.vue' /* webpackChunkName: "" */))
const _1d8063d8 = () => interopDefault(import('../_templates/pages/checkout/Checkout/Order.vue' /* webpackChunkName: "" */))
const _1e9df2ff = () => interopDefault(import('../_templates/pages/checkout/Checkout/ThankYou.vue' /* webpackChunkName: "" */))
const _50f23c56 = () => interopDefault(import('../_templates/pages/wishlist/Wishlist.vue' /* webpackChunkName: "" */))
const _481a6692 = () => interopDefault(import('../modules/oci/pages/OciCart.vue' /* webpackChunkName: "" */))
const _452e2a75 = () => interopDefault(import('../_templates/pages/customer/MyAccount/ResetPassword.vue' /* webpackChunkName: "" */))
const _2534fb1a = () => interopDefault(import('../pages/customer/MyAccount/MyAccount.vue' /* webpackChunkName: "" */))
const _3921e3b6 = () => interopDefault(import('../_templates/pages/customer/MyAccount/MyProfile/MyProfile.vue' /* webpackChunkName: "" */))
const _c82b2c8c = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressesDetails.vue' /* webpackChunkName: "" */))
const _566b0206 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressNew.vue' /* webpackChunkName: "" */))
const _f20b60d8 = () => interopDefault(import('../_templates/pages/customer/MyAccount/AddressesDetails/AddressEdit.vue' /* webpackChunkName: "" */))
const _96c18c0c = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/OrderHistory.vue' /* webpackChunkName: "" */))
const _8437ce8e = () => interopDefault(import('../_templates/pages/customer/MyAccount/OrderHistory/SingleOrder/SingleOrder.vue' /* webpackChunkName: "" */))
const _754a80ff = () => interopDefault(import('../modules/my-account/pages/CompanyInformation.vue' /* webpackChunkName: "" */))
const _73ae5eb8 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollection.vue' /* webpackChunkName: "" */))
const _5e750857 = () => interopDefault(import('../_templates/pages/attribute-collection/AttributeCollectionItem.vue' /* webpackChunkName: "" */))
const _119741e3 = () => interopDefault(import('../_templates/pages/faq/Faq.vue' /* webpackChunkName: "" */))
const _a2000596 = () => interopDefault(import('../_templates/pages/blog/BlogPost.vue' /* webpackChunkName: "" */))
const _68b9c6f5 = () => interopDefault(import('../_templates/pages/blog/Blog.vue' /* webpackChunkName: "" */))
const _4e6e95f5 = () => interopDefault(import('../_templates/pages/search/Search.vue' /* webpackChunkName: "" */))
const _4e8eb73f = () => interopDefault(import('../_templates/pages/theme/Page.vue' /* webpackChunkName: "" */))
const _034cbce2 = () => interopDefault(import('../_templates/pages/theme/Home.vue' /* webpackChunkName: "" */))
const _02947944 = () => interopDefault(import('../pages/customer/MyAccount/useSidebarLinkGroups.ts' /* webpackChunkName: "pages/customer/MyAccount/useSidebarLinkGroups" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/checkout",
    component: _19e59a16,
    name: "checkout",
    children: [{
      path: "konto",
      component: _9c2d941a,
      name: "checkout-account"
    }, {
      path: "bestilling",
      component: _1d8063d8,
      name: "checkout-order"
    }, {
      path: "takk",
      component: _1e9df2ff,
      name: "checkout-thank-you"
    }]
  }, {
    path: "/onskeliste",
    component: _50f23c56,
    name: "wishlist"
  }, {
    path: "/oci-cart",
    component: _481a6692,
    name: "oci-cart"
  }, {
    path: "/customer/reset-password",
    component: _452e2a75,
    alias: "/customer/account/createPassword",
    name: "reset-password"
  }, {
    path: "/customer",
    component: _2534fb1a,
    meta: {"titleLabel":"Account"},
    name: "customer",
    children: [{
      path: "my-profile",
      component: _3921e3b6,
      meta: {"titleLabel":"My Account"},
      name: "customer-my-profile"
    }, {
      path: "addresses-details",
      component: _c82b2c8c,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details"
    }, {
      path: "addresses-details/create",
      component: _566b0206,
      meta: {"titleLabel":"Addresses details"},
      name: "customer-addresses-details-new"
    }, {
      path: "addresses-details/edit/:addressId",
      component: _f20b60d8,
      meta: {"titleLabel":"Addresses details"},
      props: true,
      name: "customer-addresses-details-edit"
    }, {
      path: "order-history",
      component: _96c18c0c,
      meta: {"titleLabel":"Order history"},
      name: "customer-order-history"
    }, {
      path: "order-history/:orderId",
      component: _8437ce8e,
      meta: {"titleLabel":"Order history"},
      props: true,
      name: "customer-single-order"
    }, {
      path: "company-information",
      component: _754a80ff,
      meta: {"titleLabel":"Company Information"},
      name: "customer-company-information"
    }]
  }, {
    path: "/collection/:slug([^/.]+).html",
    component: _73ae5eb8,
    name: "attributeCollection"
  }, {
    path: "/collection/:collection_slug/:slug+.html",
    component: _5e750857,
    name: "attributeCollectionItem"
  }, {
    path: "/faq.html",
    component: _119741e3,
    name: "faq"
  }, {
    path: "/aktuelt/:slug+.html",
    component: _a2000596,
    name: "blogPost"
  }, {
    path: "/aktuelt.html",
    component: _68b9c6f5,
    name: "blog"
  }, {
    path: "/search",
    component: _4e6e95f5,
    name: "search"
  }, {
    path: "/:slug+",
    component: _4e8eb73f,
    name: "page"
  }, {
    path: "/",
    component: _034cbce2,
    name: "home"
  }, {
    path: "/customer/MyAccount/MyAccount",
    component: _2534fb1a,
    name: "customer-MyAccount-MyAccount"
  }, {
    path: "/customer/MyAccount/useSidebarLinkGroups",
    component: _02947944,
    name: "customer-MyAccount-useSidebarLinkGroups"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
