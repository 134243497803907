import type { Plugin } from '@nuxt/types';
import { isOciCookieName } from '~/modules/oci/constants';

const moduleOptions = `{"isOci":false,"nonOciBaseUrl":"http://localhost:3000","cookiesDefaultOpts":{"httpOnly":false,"secure":false,"sameSite":"Strict","path":"/"}}`;

const ociMiddlewarePlugin: Plugin = ({ app }) => {
  const options = JSON.parse(moduleOptions)
  if (!options.isOci) return;
  const ociCookie = !!app.$cookies.get(isOciCookieName, options.cookieDefaultOpts);
  if (!ociCookie) {
    if (window && options.nonOciBaseUrl) {
      window.location.href = options.nonOciBaseUrl;
    }
  }
}

export default ociMiddlewarePlugin;