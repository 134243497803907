

































import {
  defineComponent,
  ref,
  useContext,
  computed
} from '@nuxtjs/composition-api';
import { SfModal, SfButton, SfHeading } from '@storefront-ui/vue';

type Website = {
  name: string;
  url: string;
  defaultStoreView: string;
}

export default defineComponent({
  name: 'WebsiteSwitcher',
  components: {
    SfModal,
    SfButton,
    SfHeading
  },
  setup () {
    const { $config, app: { i18n } } = useContext();
    const websites: Website[] = $config.websites;
    const activeStore = i18n.locale;
    const isConfirmationModalActive = ref(false);
    const storeToSwitch = ref<Website | null>(null);

    const confirmSwitchStore = (website: Website) => {
      storeToSwitch.value = website;
      isConfirmationModalActive.value = true;
    }

    const switchStore = () => {
      if (storeToSwitch.value) {
        window.location.href = storeToSwitch.value.url;
      }
    }

    const confirmationModalTitle = computed(() => {
      if (!storeToSwitch.value) {
        return '';
      }
      return storeToSwitch.value.defaultStoreView === 'storsjokken'
        ? 'NB! Du forlater nå Foodtech Industri'
        : 'NB! Du forlater nå Foodtech Interiør og Storkjøkken';
    });

    const confirmationModalText = computed(() => {
      if (!storeToSwitch.value) {
        return '';
      }

      return storeToSwitch.value.defaultStoreView === 'storsjokken'
        ? 'Dersom du er kunde hos Foodtech Industri, får du ikke handlet hos Foodtech Interiør og Storkjøkken'
        : 'Dersom du er kunde hos Foodtech Interiør og Storkjøkken får du ikke handlet hos Foodtech Industri'
    })

    return {
      confirmSwitchStore,
      switchStore,
      confirmationModalTitle,
      confirmationModalText,
      storeToSwitch,
      isConfirmationModalActive,
      websites,
      activeStore
    }
  }
})
