const moduleOptions = JSON.parse(`{"allowRegistration":false,"registrationPage":"","accountMenuGroups":[[{"label":"Order history","to":{"name":"customer-order-history"}},{"label":"Company information","to":{"name":"customer-company-information"}}]]}`);
var plugin_default = (context, inject) => {
  var _a;
  let wm = (_a = context.$wm) != null ? _a : {};
  wm.customer = moduleOptions;
  inject("wm", wm);
};
export {
  plugin_default as default
};
